import { hashById } from '../utils/index.js'

const initalFriendList = [
	// {
	// 	userName 	: "oliviajohnson",
	// 	name 	 	: "Olivia Johnson",
	// 	profilePic  : "https://neshwadev.s3.us-east-2.amazonaws.com/userphotos/Olivia+Johnson.jpeg",
	// 	status 		: "Invite",
	// 	roomName 	: "marygrace_johnsmith"
	// },
	// {
	// 	userName 	: "johnsmith",
	// 	name 	 	: "John Smith",
	// 	profilePic  : "https://neshwadev.s3.amazonaws.com/test/0_XJXZLdTwAbCY49mP.jpg",
	// 	status 		: "Send",
	// 	roomName 	: "marygrace_johnsmith"
	// },
	// {
	// 	userName 	: "avasmith",
	// 	name 	 	: "Ava Smith",
	// 	profilePic  : "https://neshwadev.s3.us-east-2.amazonaws.com/userphotos/Ava+Smith.jpeg",
	// 	status 		: "Invite",
	// 	roomName 	: "marygrace_johnsmith"
	// },
];

const friendListReducer = (state = initalFriendList , action ) => {
	const { type, payload } = action;
	switch(type){
		case 'LIST' : 
			return payload
		case 'INVITE' :
		case 'LEAVE' : 
			return state
		default : 
			return state
	}

	return state;
}

export default friendListReducer