import React from 'react';
import { useDispatch } from "react-redux";
import {VideoUserInvite} from "./actions/videoActions.js";

const User = (props) => {
	const dispatch = useDispatch();
	const inviteFriend = () => {
		VideoUserInvite(props.userName,props.roomName)
	}
	return (
      	<div class="chat_list active_chat">
		  <div class="chat_people">
		    <div class="chat_img"> <img src={props.profilePic} alt={props.name} class="img-circle" /> </div>
		    <div class="chat_ib">
		      <h5>{props.name} 
		      		{
		      			props.status === 'Send' ? 
			      			<span class="greendot"></span>
			      		:
  				      		<button onClick={inviteFriend} type="button" style={{ marginLeft: "17px", borderRadius: "25px", fontSize: "10px", width: "80px" }} class="btn btn-primary btn-sm">Invite</button>
			      	}
		      	</h5>

		    </div>
		  </div>
		</div>
    );
}

export default User;