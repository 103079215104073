import React, { useState, useEffect } from 'react';
import Video from 'twilio-video';
import Participant from './Participant';
import User from './User'

import { useSelector,useDispatch } from "react-redux";
import { onlineUserList } from './actions/userActions';

import { getData } from './actions/userActions.js'
const Room = ({ roomName, token, userName, handleLogout }) => {
  const allUsers = useSelector((state) => state.friendList );
  const dispatch = useDispatch();
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [toggleAudio, setToggleAudio] = React.useState(false);
  const [toggleVideo, setToggleVideo] = React.useState(false);
  const [videoStatus, changeVideoStatus] = useState('Hide Video');
  const [audioStatus, changeAudioStatus] = useState('Unmute');
  useEffect(() => {
    dispatch(getData({userName,roomName}))
    const participantConnected = participant => {
      setParticipants(prevParticipants => [...prevParticipants, participant]);
    };
    const participantDisconnected = participant => {
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p !== participant)
      );
    };
    Video.connect(token, {
      name: roomName
      // name: this.props.roomName
    }).then(room => {
      setRoom(room);
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      room.participants.forEach(participantConnected);
    });
    return () => {
      setRoom(currentRoom => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach(function (trackPublication) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          window.close();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);
  const handleAudioToggle = () => {
    room.localParticipant.audioTracks.forEach(track => {
      if (track.track.isEnabled) {
        track.track.disable();
        changeAudioStatus('Unmute');
      } else {
        track.track.enable();
        changeAudioStatus('Mute');
      }
      setToggleAudio(!track.track.isEnabled);
    });
  };
  const handleVideoToggle = () => {
    room.localParticipant.videoTracks.forEach(track => {
      if (track.track.isEnabled) {
        track.track.disable();
        changeVideoStatus('Show Video');
      } else {
        track.track.enable();
        changeVideoStatus('Hide Video');
      }
      setToggleVideo(!track.track.isEnabled);
    });
  };
  if (participants.length > 6) {
    return (<div>Max Allowed Participants exceeded i.e. 5</div>);
  } else {
    const remoteParticipants = participants.map(participant => (
      <Participant
        key={participant.sid}
        participant={participant}
        handleAudioToggle={handleAudioToggle}
        handleVideoToggle={handleVideoToggle}
        buttonHideShow={false}
        videoStatus={videoStatus}
        audioStatus={audioStatus}
      // toggleAudio={toggleAudio}
      // toggleVideo={toggleVideo}
      />
    ));

    return (
        <div class="row top30 chatbox">
          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
            <div class="row">
              <div class=" col-lg-12 col-sm-12 col-md-12 col-xs-12">
                <div class="chat_list active_chat">
                  <div class="chat_people">
                    <div class="chat_img">
                        <img src="/images/download.png" alt="sunil" class="img-circle"/> 
                    </div>
                    <div class="chat_ib">
                      <a data-toggle="collapse" href="#usercollapse" role="button" aria-expanded="false"
                        aria-controls="usercollapse">
                        <h5>Invite Friends</h5>
                      </a>

                    </div>
                  </div>
                </div>
                <div class="collapse" id="usercollapse">
                  {
                    allUsers && allUsers.length > 0 && allUsers.map((user,index) =>{
                        return <User key={index} userName={user.userName} name={user.name} profilePic={user.profilePic} status={user.status} roomName={user.roomName}/>
                    })
                  }  
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            {/* <button onClick={handleLogout} style={{marginLeft:"780px",position: "relative"}}>Leave</button> */}
            <div className="row">
              {remoteParticipants}
            
              {room ? (
                <Participant
                  participant={room.localParticipant}
                  key={room.localParticipant.sid}
                  handleAudioToggle={handleAudioToggle}
                  handleVideoToggle={handleVideoToggle}
                  buttonHideShow={true}
                  videoStatus={videoStatus}
                  audioStatus={audioStatus}
                />
              ) : (
                  ''
                )}

            </div>
            <button onClick={handleLogout}>Leave</button> 

        </div>
        </div>
    )
  }
}
export default Room;



{/* //   <h2>Room: {roomName}</h2>
      //   <button onClick={handleLogout}>Log out</button>
      //   <button onClick={handleVideoToggle}>{videoStatus}</button>
      //   <button onClick={handleAudioToggle}>{audioStatus}</button>
      //   <div className="local-participant">
      //     {room ? ( */}
      //       <Participant
      //         key={room.localParticipant.sid}
      //         participant={room.localParticipant}
      //         handleAudioToggle={handleAudioToggle}
      //         handleVideoToggle={handleVideoToggle}
      //       />
      //     ) : (
      //       ''
      //     )}
      //   </div>
      //   <h3>Remote Participants</h3>
      //   <div className="remote-participants">{remoteParticipants}</div>
      // </div>



// import React, { useState, useEffect } from 'react';
// import Video from 'twilio-video';
// import Participant from './Participant';
// // import ListUsers from './ListUsers';
// const Room = ({ roomName, token, handleLogout }) => {
//   const [room, setRoom] = useState(null);
//   const [participants, setParticipants] = useState([]);
//   const [toggleAudio, setToggleAudio ] = React.useState(false);
//   const [toggleVideo, setToggleVideo] = React.useState(false);
//   // let videoStatus = "Hide Video";
//   const [videoStatus, changeVideoStatus] = useState('Hide Video');
//   const [audioStatus, changeAudioStatus] = useState('Unmute');

//   useEffect(() => {
//     const participantConnected = participant => {
//       setParticipants(prevParticipants => [...prevParticipants, participant]);
//     };

//     const participantDisconnected = participant => {
//       setParticipants(prevParticipants =>
//         prevParticipants.filter(p => p !== participant)
//       );
//     };

//     Video.connect(token, {
//       name: roomName
//     }).then(room => {
//       setRoom(room);
//       room.on('participantConnected', participantConnected);
//       room.on('participantDisconnected', participantDisconnected);
//       room.participants.forEach(participantConnected);
//     });

//     return () => {
//       setRoom(currentRoom => {
//         if (currentRoom && currentRoom.localParticipant.state === 'connected') {
//           currentRoom.localParticipant.tracks.forEach(function(trackPublication) {
//             trackPublication.track.stop();
//           });
//           currentRoom.disconnect();
//           return null;
//         } else {
//           return currentRoom;
//         }
//       });
//     };
//   }, [roomName, token]);

//   const handleAudioToggle = () => {
//     room.localParticipant.audioTracks.forEach(track => {
//       if (track.track.isEnabled) {
//         track.track.disable();
//         changeAudioStatus('Unmute');
//       } else {
//         track.track.enable();
//         changeAudioStatus('Mute');
//       }
//       setToggleAudio(!track.track.isEnabled);
//     });
//   };

//   const handleVideoToggle = () => {
//     room.localParticipant.videoTracks.forEach(track => {
//       if (track.track.isEnabled) {
//         track.track.disable();
//         changeVideoStatus('Show Video');
//       } else {
//         track.track.enable();
//         changeVideoStatus('Hide Video');
//       }
//       setToggleVideo(!track.track.isEnabled);
//     });
//   };

//     console.log("length ",participants.length)
//   if(participants.length > 3){
//     return (<div>Max Allowed Participants exceeded i.e. 4</div>);
//   }else{
//       const remoteParticipants = participants.map(participant => (
//         <Participant 
//                 key={participant.sid} 
//                 participant={participant} 
//                 handleAudioToggle={handleAudioToggle}
//                 handleVideoToggle={handleVideoToggle}
//                 // toggleAudio={toggleAudio}
//                 // toggleVideo={toggleVideo}
//         />
//       ));

//     return (
//       <div className="room">

//         <h2>Room: {roomName}</h2>
//         <button onClick={handleLogout}>Log out</button>
//         <button onClick={handleVideoToggle}>{videoStatus}</button>
//         <button onClick={handleAudioToggle}>{audioStatus}</button>
//         <div className="local-participant">
//           {room ? (
//             <Participant
//               key={room.localParticipant.sid}
//               participant={room.localParticipant}
//               handleAudioToggle={handleAudioToggle}
//               handleVideoToggle={handleVideoToggle}
//             />
//           ) : (
//             ''
//           )}
//         </div>
//         <h3>Remote Participants</h3>
//         <div className="remote-participants">{remoteParticipants}</div>
//       </div>
//     );
//   }
// };

// export default Room;
