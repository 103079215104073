import React, { Component } from 'react'
import { APIURL } from './common/constant';
import Room from './Room';
import { VideoUserLeave } from './actions/videoActions'


export default class VideoChat extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: ""
    }
  }

  async componentDidMount() {
      const data = await fetch(APIURL + '/token/video', {
        method: 'POST',
        body: JSON.stringify({
          identity: this.props.userName,
          room:  this.props.roomName
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => res.json());
      this.setState({token : data.token});
      
  }

  handleLogout = () =>{
    this.setState({token : ""})
    VideoUserLeave(this.props.userName,this.props.roomName)
    //window.close();
  }

  render() {
    return (
     
      <div>
       

        <Room roomName={this.props.roomName} token={this.state.token} userName={this.props.userName} handleLogout={() => this.handleLogout()} />
    
      </div>
    )
  }
}
