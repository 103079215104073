import React from 'react';
import './App.css';
import VideoChat from './VideoChat';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


const App = () => {
  // console.log("App ",props.match.params.id)
  return (
    <div className="app">
      <header>
        <h3>NESHWA VIDEO CHAT</h3>
      </header>
      <main>

        {/* <VideoChat /> */}
        <Router>
          <Switch>
            // <Route path="/room/:roomName/:id" exact render={(props) => (<VideoChat  userName={props.match.params.id} roomName={props.match.params.roomName}  />)} />
            <Route path="/room/:roomName/:id/:name" exact render={(props) => (<VideoChat  userName={props.match.params.id} roomName={props.match.params.roomName} name={props.match.params.name}  />)} />
          </Switch>
        </Router> 

      </main>
      
    </div>
  );
};

export default App;
