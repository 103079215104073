import axios from 'axios';
import { APIURL } from '../common/constant.js';

export const getData = ({userName,roomName}) => async dispatch => {
	console.log("get data",userName)
	console.log("get data",roomName)
	const response = await axios.get(APIURL+'/api/videoroom/get/invitefriendslistwithstatus/'+userName+'/'+roomName);
	console.log("data",response.data.friendList)
	// // let userNameData = [];
	// // let nameData = [];
	// // let profilePicData = [];
	// // let statusData = [];
	// // let roomNameData = [];
	// let friendListData = []
	// var i = 0;
	// for(i = 0 ; i < response.data.friendList.length ; i++){
	// 	friendListData.push({
	// 		userName 	: response.data.friendList[i].userName,
	// 		name 	 	: response.data.friendList[i].name,
	// 		profilePic 	: response.data.friendList[i].profilePic,
	// 		status  	: response.data.friendList[i].status,
	// 		roomName 	: response.data.friendList[i].roomName
	// 	})
	// 	// userNameData.push(response.data.friendList[i].userName)
	// 	// nameData.push(response.data.friendList[i].name)
	// 	// profilePicData.push(response.data.friendList[i].profilePic)
	// 	// statusData.push(response.data.friendList[i].status)
	// 	// roomNameData.push(response.data.friendList[i].roomName)
	// }
	dispatch({
		type : "LIST",
		payload : response.data.friendList
		// payload : {
		// 		userName 	: userNameData,
		// 		name 	 	: nameData,
		// 		profilePic  : profilePicData,
		// 		status 		: statusData,
		// 		roomName 	: roomNameData
		// }
	})
}

