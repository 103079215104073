import { LocalParticipant } from "twilio-video";
import React, { useState, useEffect, useRef } from "react";
import './Participant.css';



const Participant = ({ participant, handleVideoToggle, handleAudioToggle, buttonHideShow, videoStatus, audioStatus,}) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  
  
  
  const videoRef = useRef();
  const audioRef = useRef();
  

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

    useEffect(() => {
      setVideoTracks(trackpubsToTracks(participant.videoTracks));
      setAudioTracks(trackpubsToTracks(participant.audioTracks));

      const trackSubscribed = (track) => {
        if (track.kind === "video") {
          setVideoTracks((videoTracks) => [...videoTracks, track]);
        } else if (track.kind === "audio") {
          setAudioTracks((audioTracks) => [...audioTracks, track]);
        }
      };

      const trackUnsubscribed = (track) => {
        if (track.kind === "video") {
          setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
        } else if (track.kind === "audio") {
          setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
        }
      };

      participant.on("trackSubscribed", trackSubscribed);
      participant.on("trackUnsubscribed", trackUnsubscribed);

      return () => {
        setVideoTracks([]);
        setAudioTracks([]);
        participant.removeAllListeners();
      };
    }, [participant]);


    useEffect(() => {
      const videoTrack = videoTracks[0];
      if (videoTrack) {
        videoTrack.attach(videoRef.current);
        return () => {
          videoTrack.detach();
        };
      }
    }, [videoTracks]);

    useEffect(() => {
      const audioTrack = audioTracks[0];
      if (audioTrack) {
        audioTrack.attach(audioRef.current);
        return () => {
          audioTrack.detach();
        };
      }
    }, [audioTracks]);
 

      return (
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">   
           
      
          {/* <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3" style={{"padding-top": "20px"}}>       */}
            

                 {/* <div div class="col-auto paddingcol ">    */}


              <video ref={videoRef} autoPlay={false} />
              <audio ref={audioRef} autoPlay={true} muted={false} />
              <span class="usernamevideo">{(participant.identity).charAt(0).toUpperCase() + (participant.identity).slice(1)}</span>
              {buttonHideShow ?
                <span class="videomute" >
                  {audioStatus === 'Mute' ?
                    <i class="fa fa-microphone" aria-hidden="true" onClick={handleAudioToggle} style={{ "margin": "3px", }}></i>
                    : <i class="fa fa-microphone-slash" aria-hidden="true" onClick={handleAudioToggle} style={{ "margin": "3px", }}></i>}

                  {videoStatus === 'Hide Video' ?
                    <i class="fa fa-video-camera" aria-hidden="true" onClick={handleVideoToggle}></i>
                    : <i class="fa fa-camera" aria-hidden="true" onClick={handleVideoToggle}></i>}
                     {/* : <img src="/images/camera_video_off.png" aria-hidden="true" onClick={handleVideoToggle} style={{"fontSize":"20px"}}></img>} */}
                    </span>
                : ""}
               
                   </div> 
            
          
           
        

  );
};


export default Participant;
